<template>
  <div class="wrongMessage-container">
    <div class="tabbar-container">
      <van-icon class="popup-back" @click="Back" name="arrow-left" />
      错题分析
    </div>

    <div class="middle-container">
      <div v-show="jShow" class="message-container">
        判断题
        <van-cell v-for="(item, index) in jListData" :key="index">
          <div style="color: black">{{ index + 1 }}.{{ item.shiti }}</div>
          <div class="j-xx">
            <div
              style="margin-right: 1rem"
              v-for="(xitem, xindex) in item.xuanxiang"
              :key="xindex"
            >
              {{ xitem.ans }}
            </div>
          </div>
          <div style="margin-top: 0.5rem">
            正确答案：<span style="color: #2cd664; margin-right: 1rem">{{
              item.paperanswers
            }}</span
            ><span style="color: #ff1a1a; margin-right: 1rem"
              >学员答案：{{ item.useranswers }}</span
            >
          </div>
        </van-cell>
      </div>
      <div v-show="sShow" class="message-container">
        单选题
        <van-cell v-for="(item, index) in sListData" :key="index">
          <div style="color: black">{{ index + 1 }}.{{ item.shiti }}</div>
          <div class="c-xx">
            <div
              style="margin-right: 1rem"
              v-for="(xitem, xindex) in item.xuanxiang"
              :key="xindex"
            >
              {{ xitem.ans }}.{{ xitem.text }}
            </div>
          </div>
          <div style="margin-top: 0.5rem">
            正确答案：<span style="color: #2cd664; margin-right: 1rem">{{
              item.paperanswers
            }}</span
            ><span style="color: #ff1a1a; margin-right: 1rem"
              >学员答案：{{ item.useranswers }}</span
            >
          </div>
        </van-cell>
      </div>
      <div v-show="mShow" class="message-container">
        多选题
        <van-cell v-for="(item, index) in mListData" :key="index">
          <div style="color: black">{{ index + 1 }}.{{ item.shiti }}</div>
          <div class="c-xx">
            <div
              style="margin-right: 1rem"
              v-for="(xitem, xindex) in item.xuanxiang"
              :key="xindex"
            >
              {{ xitem.ans }}.{{ xitem.text }}
            </div>
          </div>
          <div style="margin-top: 0.5rem">
            正确答案：<span style="color: #2cd664; margin-right: 1rem">{{
              item.paperanswers
            }}</span
            ><span style="color: #ff1a1a; margin-right: 1rem"
              >学员答案：{{ item.useranswers }}</span
            >
          </div>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wrongMessage",
  components: {},
  computed: {},
  props: {
    message: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      jShow: true,
      sShow: true,
      mShow: true,
      jListData: [],
      sListData: [],
      mListData: [],
    };
  },
  watch: {},
  created() {},
  async mounted() {
    await this.loadData();
  },
  destroyed() {},
  methods: {
    async loadData() {
      const cuotiForm = {
        khid: localStorage.getItem("openid"),
        submitdate: this.message.submitdate,
      };
      const CuoTi = await this.$store.dispatch("api/getCuoTiDetail", cuotiForm);

      if (CuoTi.success) {
        CuoTi.result.forEach((c) => {
          c.xuanxiang = JSON.parse(c.xuanxiang);
          switch (c.ttype) {
            case "判断":
              this.jListData.push(c);
              break;
            case "单选":
              this.sListData.push(c);
              break;
            case "多选":
              this.mListData.push(c);
              break;
          }
        });
        if (this.jListData.length === 0) {
          this.jShow = false;
        }
        if (this.sListData.length === 0) {
          this.sShow = false;
        }
        if (this.mListData.length === 0) {
          this.mShow = false;
        }
      } else {
        this.$toast(CuoTi.message);
      }
    },
    Back() {
      this.$emit("wrongMessageBack");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrongMessage-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-image: linear-gradient(#2d8efc, #1ccaa3);
  overflow-x: hidden;
}
.tabbar-container {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 1rem;
  position: relative;
  font-size: 1.1rem;

  .popup-back {
    position: absolute;
    left: 20px;
    top: 5px;
  }
}

.middle-container {
  width: 100%;
  flex: 1;
  height: 0;
  padding: 0 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  overflow-y: auto;

  .message-container {
    width: 100%;
    color: #a5a5a5;
    background: white;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 1rem;

    /deep/ .van-cell {
      padding: 10px 0;
    }

    /deep/ .van-cell::after {
      right: 0;
      left: 0;
    }

    .j-xx {
      color: #686868;
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;
    }
    .c-xx {
      color: #686868;
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
