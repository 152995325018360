<template>
  <div class="wh100">
    <div class="tabbar-container">
      <div class="tabbar-title">首页</div>
      <div v-show="userView" class="tabbar-user">
        {{ nickname }}
        <van-image
          style="padding-left: 0.5rem"
          @click="showMNKS"
          width="2rem"
          height="2rem"
          round
          fit="contain"
          :src="headimgurl"
        />
      </div>
    </div>
    <div style="padding: 0 15px">
      <van-image width="100%" :src="banner" />
    </div>
    <div class="middle-container">
      <van-image
        @click="showMNKS"
        width="50%"
        height="7rem"
        fit="contain"
        :src="mnks"
      />
      <van-image
        @click="showZXLX"
        width="50%"
        height="7rem"
        fit="contain"
        :src="zxlx"
      />
    </div>

    <van-grid
      :border="false"
      :gutter="10"
      style="margin-top: 10px"
      :column-num="2"
    >
      <van-grid-item
        v-for="(item, index) in itemList"
        @click="gridClick(item)"
        :key="index"
      >
        <div class="grid-item">
          <van-image width="55px" height="55px" fit="contain" :src="item.img" />
          <div class="grid-text">{{ item.tab }}</div>
        </div>
      </van-grid-item>
    </van-grid>

    <van-popup
      v-model="showExamination"
      position="right"
      :lock-scroll="false"
      :style="{ width: '100%', height: '100%' }"
    >
      <examination
        v-if="showExamination"
        :queType="queType"
        :specialType="specialType"
        @fatherMethod="Back"
        @fatherMethod2="Back2"
      />
    </van-popup>

    <van-popup
      v-model="showCustomList"
      position="right"
      :lock-scroll="false"
      :style="{ width: '100%', height: '100%' }"
    >
      <custom-list :customType="customType" @customListBack="customListBack" />
    </van-popup>

    <van-popup
      v-model="showSpecial"
      position="right"
      :lock-scroll="false"
      :style="{ width: '100%', height: '100%' }"
    >
      <special @specialChoose="specialChoose" @specialBack="specialBack" />
    </van-popup>

    <van-popup
      v-model="showAnalysis"
      position="right"
      :lock-scroll="false"
      :style="{ width: '100%', height: '100%' }"
    >
      <analysis v-if="showAnalysis" @analysisBack="analysisBack" />
    </van-popup>

    <van-popup
      v-model="showWrongQue"
      position="right"
      :lock-scroll="false"
      :style="{ width: '100%', height: '100%' }"
    >
      <wrong-que v-if="showWrongQue" @wrongQueBack="wrongQueBack" />
    </van-popup>

    <transition name="van-slide-down">
      <div class="fade" v-show="visiable">功能正在开发中</div>
    </transition>
  </div>
</template>

<script>
import Utils from '../../utils/util.js'
import { Dialog } from 'vant'
import { mapState } from 'vuex'
import examination from './examination.vue'
import customList from './customList.vue'
import special from './special.vue'
import analysis from './analysis.vue'
import wrongQue from './wrongQue.vue'
export default {
  name: 'appMenu',
  components: { examination, customList, special, analysis, wrongQue },
  computed: {
    ...mapState({
      mainRoutes(state) {
        const arrGroup = this.$arrayGroup(state.permission.routes, 'type')
        const re = this.$getTypeName(arrGroup, 'type', '1', {})
        if (re && re.data) {
          return re.data
        } else {
          return []
        }
      },
    }),
  },
  data() {
    return {
      visiable: false,
      customType: 0,

      // 答题为0：模拟考试，1：专项练习。
      queType: 0,
      // 专项练习0：判断题，1：单选题，2：多选题。
      specialType: 0,

      // 展开模拟考试。
      showExamination: false,
      // 展开自定义考场。
      showCustomList: false,
      // 展开专项练习。
      showSpecial: false,
      // 展开练题分析。
      showAnalysis: false,
      // 展开错题集。
      showWrongQue: false,

      // 首页用户头像
      headimgurl: '',
      // 首页用户微信名称
      nickname: '',
      // 是否显示用户肖像
      userView: false,

      banner: require('../../assets/icon/banner.png'),
      mnks: require('../../assets/icon/icon_mnks.png'),
      zxlx: require('../../assets/icon/icon_zxlx.png'),

      // grid
      itemList: [
        {
          tab: '自定义考场',
          img: require('../../assets/icon/zdykc.png'),
        },
        {
          tab: '错题集',
          img: require('../../assets/icon/icon_ctj.png'),
        },
        {
          tab: '考试分析',
          img: require('../../assets/icon/icon_grfx.png'),
        },
        // {
        //   tab: '全国排行榜',
        //   img: require('../../assets/icon/icon_phb.png')
        // },
        // {
        //   tab: '个人成就',
        //   img: require('../../assets/icon/icon_grcj.png')
        // }
      ],
    }
  },
  watch: {},
  created() {},
  mounted() {
    if (localStorage.getItem('openid')) {
      this.userView = true
      this.nickname = localStorage.getItem('nickname')
      this.headimgurl = localStorage.getItem('headimgurl')
      return
    } else {
      this.userView = false
    }
    if (this.getUrlParam('code')) {
      localStorage.setItem('code', this.getUrlParam('code'))
      window.location.href =
        `https://` +
        this.$store.getters.appconfig.wxConfig.webUrl +
        `?version=${new Date().getTime()}`
      return
    }

    if (localStorage.getItem('code')) {
      this.$store
        .dispatch('api/getUserInfo', { code: localStorage.getItem('code') })
        .then((response) => {
          localStorage.setItem('openid', response.openid)
          localStorage.setItem('headimgurl', response.headimgurl)
          localStorage.setItem('nickname', response.nickname)

          this.userView = true
          this.nickname = localStorage.getItem('nickname')
          this.headimgurl = localStorage.getItem('headimgurl')
          localStorage.setItem('code', '')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  destroyed() {},
  methods: {
    waitTip() {
      if (this.visiable) return

      this.visiable = true
      setTimeout(() => {
        this.visiable = false
      }, 1000)
    },

    getUrlParam(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg) //匹配目标参数
      if (r != null) return unescape(r[2])
      return null //返回参数值
    },

    // 是否登录
    isLogin() {
      if (localStorage.getItem('openid')) {
        return true
      } else {
        Dialog.confirm({
          title: '您未登录，请先登录后使用模拟考试',
          showCancelButton: false,
          confirmButtonText: '去登录',
        }).then(() => {
          Utils.$emit('my')
        })
        return false
      }
    },

    // 是否信息完善
    async isMessage() {
      const student = await this.$store.dispatch('api/getUserInfoForId', {
        studentid: localStorage.getItem('openid'),
      })
      if (student.result.idcard) {
        return true
      } else {
        Dialog.confirm({
          message: '信息完善未完善\n请去【我的-信息完善】中补全信息',
          showCancelButton: false,
          confirmButtonText: '去完善',
        }).then(() => {
          this.Back2()
        })
        return false
      }
    },

    showMNKS() {
      if (this.isLogin()) {
        if (this.isMessage()) {
          this.queType = 0
          this.showExamination = true
        }
      }
    },
    // 专项练习。
    showZXLX() {
      if (this.isLogin()) {
        if (this.isMessage()) {
          this.queType = 1
          this.showSpecial = true
        }
      }
    },
    Back() {
      this.showExamination = false
    },
    Back2() {
      this.showExamination = false
      setTimeout(() => {
        Utils.$emit('my')
      }, 500)
    },
    customListBack() {
      this.showCustomList = false
    },
    specialBack() {
      this.showSpecial = false
    },
    analysisBack() {
      this.showAnalysis = false
    },
    wrongQueBack() {
      this.showWrongQue = false
    },
    specialChoose(type) {
      this.specialType = type
      this.showSpecial = false

      setTimeout(() => {
        this.showExamination = true
      }, 200)
    },
    gridClick(item) {
      switch (item.tab) {
        case '自定义考场':
          if (this.isLogin()) {
            if (this.isMessage()) {
              this.showCustomList = true
            }
          }
          break
        case '错题集':
          if (this.isLogin()) {
            if (this.isMessage()) {
              this.showWrongQue = true
            }
          }
          break
        case '考试分析':
          if (this.isLogin()) {
            if (this.isMessage()) {
              this.showAnalysis = true
            }
          }
          break
        case '全国排行榜':
          this.waitTip()
          break
        case '个人成就':
          this.waitTip()
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tabbar-container {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  .tabbar-title {
    color: #000;
    font-weight: bold;
    font-size: 1.1rem;
  }

  .tabbar-user {
    color: #666666;
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .tabbar-userimg {
      width: 20px;
      height: 20px;
    }
  }
}

.middle-container {
  width: 100%;
  .middle-img {
    width: 50%;
    height: 50px;
  }
}

.app-container {
  background: white;
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
  padding-top: 0.5rem;
}

.grid-item {
  width: 100%;
  background: #f7f7f7;
  border-radius: 10px;
  height: 75px;
  display: flex;
  padding: 0 15px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;

  .grid-text {
    margin-left: 12px;
    color: #333333;
    font-size: 0.9rem;
  }
}

/deep/ .van-grid-item__content {
  padding: 0 0 !important;
}

.fade {
  width: 50%;
  height: 6%;
  border-radius: 5px;
  top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 25%;
  position: absolute;
  background-image: linear-gradient(270deg, #2d8efc, #1ccaa3);
  color: #ffffff;
}
</style>
