<template>
  <div class="custom-container">
    <div class="tabbar-container">
      <van-icon class="popup-back" @click="Back" name="arrow-left" />
      自定义考场
    </div>
    <div class="middle-container">
      <div class="middle-message">
        <div class="middle-message-view">
          <van-field
            v-model="selfData.planname"
            label="自定义名称"
            placeholder="输入自定义名称"
            input-align="right"
          />

          <van-cell title="及格分数线">
            <template #right-icon>
              <van-stepper
                v-model="selfData.cutoffscores"
                min="1"
                input-width="40px"
                step="0.5"
              />
            </template>
          </van-cell>

          <van-cell title="判断题数目">
            <template #right-icon>
              <van-stepper
                v-model="selfData.pannum"
                min="0"
                integer
                input-width="40px"
              />
            </template>
          </van-cell>

          <van-cell title="单选题数目">
            <template #right-icon>
              <van-stepper
                v-model="selfData.dannum"
                min="0"
                integer
                input-width="40px"
              />
            </template>
          </van-cell>

          <van-cell title="多选题数目">
            <template #right-icon>
              <van-stepper
                v-model="selfData.duonum"
                min="0"
                integer
                input-width="40px"
              />
            </template>
          </van-cell>

          <van-cell title="判断题单体分数">
            <template #right-icon>
              <van-stepper
                v-model="selfData.panscore"
                min="0.5"
                input-width="40px"
                step="0.5"
              />
            </template>
          </van-cell>

          <van-cell title="单选题单体分数">
            <template #right-icon>
              <van-stepper
                v-model="selfData.danscore"
                min="0.5"
                input-width="40px"
                step="0.5"
              />
            </template>
          </van-cell>

          <van-cell title="多选题单体分数">
            <template #right-icon>
              <van-stepper
                v-model="selfData.duoscore"
                min="0.5"
                input-width="40px"
                step="0.5"
              />
            </template>
          </van-cell>
        </div>
        <div class="checkout" @click="saveCustom">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "custom",
  components: {},
  computed: {},
  props: {
    self: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      selfData: {},
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.selfData = this.self;
  },
  destroyed() {},
  methods: {
    Back() {
      this.$emit("customBack");
    },
    saveCustom() {
      if (!this.selfData.planname) {
        this.$toast("请输入自定义名称");
        return;
      }
      this.$store
        .dispatch("api/saveSelfData", this.selfData)
        .then((response) => {
          if (response.success) {
            this.$emit("customBack");
          } else {
            this.$toast(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: white;
  overflow-x: hidden;
}
.tabbar-container {
  width: 100%;
  text-align: center;
  color: black;
  margin: 1rem 0;
  position: relative;
  font-size: 1.1rem;

  .popup-back {
    position: absolute;
    left: 20px;
    top: 5px;
  }
}

.middle-container {
  width: 100%;
  flex: 1;
  background: #f7f7f7;
  padding: 1rem;
  box-sizing: border-box;

  .middle-message {
    background: white;
    border-radius: 5px;
    padding-bottom: 5%;
    width: 100%;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;

    .middle-message-view {
      width: 100%;
      flex: 1;
    }
  }
}

/deep/ .van-cell {
  text-align: left;
}

.checkout {
  width: 90%;
  margin-left: 5%;
  height: 44px;
  border-radius: 999px;
  display: flex;
  align-items: row;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(270deg, #2d8efc, #1ccaa3);
  color: #ffffff;
  font-size: 1rem;
}
</style>
