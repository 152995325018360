<template>
  <div class="analysis-container">
    <div class="tabbar-container">
      <van-icon class="popup-back"
                @click="Back"
                name="arrow-left" />
      模拟考试分析
    </div>
    <div class="message-container">
      <div class="top-container">

        <div class="top-view">
          <div style="color: #1ec9a2; font-size: 1.8rem">{{ testnum }}</div>
          <div style="margin-top: 5%">模考次数</div>
        </div>

        <div class="top-line" />

        <div class="top-view">
          <div style="color: #1ec9a2; font-size: 1.8rem">{{ testpassnum }}</div>
          <div style="margin-top: 5%">合格次数</div>
        </div>

        <div class="top-line" />

        <div class="top-view">
          <div style="color: #1ec9a2; font-size: 1.8rem">{{ testbadnum }}</div>
          <div style="margin-top: 5%">不合格次数</div>
        </div>
      </div>
      <div class="middle-container">
        <div class="r">及格率 <span>平均成绩：{{testss}}</span></div>
        <div class="psen">{{ jgl }}%</div>
        <van-progress :percentage="jgl"
                      :show-pivot="false"
                      color="#1ec9a2"
                      stroke-width="20" />
      </div>
      <div id="myChart"
           class="bottom-container"></div>
      <div id="myChart2"
           class="bottom-container"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'analysis',
  components: {},
  computed: {},
  data() {
    return {
      testnum: 0,
      testquesnum: 0,
      testpassnum: 0,
      testbadnum: 0,
      testss: 0,
      jgl: 0,
      option: {
        title: {
          text: '模拟考试走势图',
          textStyle: {
            fontSize: 18
          },
          padding: [15, 0, 0, 10]
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '2%',
          right: '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#CECECE'
            }
          }
        },
        yAxis: {
          min: 0, //取0为最小刻度
          minInterval: 1,
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#CECECE'
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: {
          name: '练习次数',
          type: 'line',
          stack: 'Total',
          color: '#4192DB',
          symbolSize: 5,
          data: []
        }
      },
      option2: {
        title: {
          text: '模拟考试平均成绩走势图',
          textStyle: {
            fontSize: 18
          },
          padding: [15, 0, 0, 10]
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '2%',
          right: '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#CECECE'
            }
          }
        },
        yAxis: {
          min: 0, //取0为最小刻度
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#CECECE'
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: {
          name: '平均成绩',
          type: 'line',
          stack: 'Total',
          color: '#24BFA5',
          symbolSize: 5,
          data: []
        }
      }
    };
  },
  watch: {},
  created() {},
  async mounted() {
    const chartForm = {
      khid: localStorage.getItem('openid')
    };

    const pj = await this.$store.dispatch('api/getAnalysis', chartForm);
    if (pj.success) {
      this.testss =
        Math.floor((pj.result.score * 100) / pj.result.submit) / 100;
    }

    const chart = await this.$store.dispatch('api/getAnalysisZx', chartForm);
    if (chart.success) {
      if (chart.result && chart.result.length > 0) {
        chart.result.forEach((c) => {
          this.option.series.data.push(c.count);
          this.option2.series.data.push(
            c.count === 0 ? 0 : Math.floor((c.score * 100) / c.count) / 100
          );
          this.option.xAxis.data.push(
            this.$timestampTostr(
              new Date(c.time.split(' ')[0]).getTime(),
              'mm-dd'
            )
          );
          this.option2.xAxis.data.push(
            this.$timestampTostr(
              new Date(c.time.split(' ')[0]).getTime(),
              'mm-dd'
            )
          );
        });
      }
    }
    const student = await this.$store.dispatch('api/getStudent', {
      id: localStorage.getItem('openid')
    });

    this.testnum = student.result.testnum;
    this.testquesnum = student.result.testquesnum;
    this.testpassnum = student.result.testpassnum;
    this.testbadnum = Number(this.testnum) - Number(this.testpassnum);

    if (this.testnum != 0) {
      this.jgl = Math.floor(
        ((Number(this.testpassnum) / Number(this.testnum)) * 10000) / 100
      );
    }

    this.drawLine();
  },
  destroyed() {},
  methods: {
    Back() {
      this.$emit('analysisBack');
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'));
      let myChart2 = this.$echarts.init(document.getElementById('myChart2'));
      // 绘制图表
      myChart.setOption(this.option);
      myChart2.setOption(this.option2);
    }
  }
};
</script>

<style lang="scss" scoped>
.analysis-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(#2d8efc, #1ccaa3);
}
.tabbar-container {
  width: 100%;
  height: 2rem;
  text-align: center;
  color: white;
  margin-top: 1rem;
  position: relative;
  font-size: 1.1rem;

  .popup-back {
    position: absolute;
    left: 20px;
    top: 5px;
  }
}
.r {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.message-container {
  width: 100%;
  height: calc(100% - 2rem);
  overflow-y: auto;
  padding: 1rem;
  box-sizing: border-box;

  .top-container {
    width: 100%;
    height: 7rem;
    border-radius: 5px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1.5rem;
    box-sizing: border-box;
    justify-content: space-between;
    box-shadow: 0px 3px 5px #888888;

    .top-view {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.95rem;
      color: #a5a5a5;
    }

    .top-line {
      width: 1px;
      height: 60%;
      background: #e5e5e5;
    }
  }
  .middle-container {
    width: 100%;
    height: auto;
    color: #333333;
    font-weight: 500;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: white;
    box-shadow: 0px 3px 5px #888888;
    padding: 4%;
    box-sizing: border-box;

    /deep/ .van-progress {
      border-radius: 999px;
    }

    .psen {
      text-align: center;
      font-size: 2.5rem;
      font-weight: bold;
      margin-top: 3%;
      margin-bottom: 3%;
    }
  }
  .bottom-container {
    width: 100%;
    height: 15rem;
    margin-top: 5%;
    border-radius: 5px;
    background: white;
    box-shadow: 0px 3px 5px #888888;
  }
}
</style>
