<template>
  <div class="custom-container">
    <div class="tabbar-container">
      <van-icon class="popup-back" @click="Back" name="arrow-left" />
      专项练习
    </div>
    <div class="middle-container">
      <div
        class="middle-view"
        @click="specialChoose(item)"
        v-for="(item, index) in specialData"
        :key="index"
      >
        <van-image width="30px" height="30px" fit="contain" :src="item.img" />
        <div style="color: black; font-weight: bold; margin-left: 1rem">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "special",
  components: {},
  computed: {},
  data() {
    return {
      specialData: [
        {
          title: "判断题",
          img: require("../../assets/icon/icon_judge.png"),
          type: 0,
        },
        {
          title: "单选题",
          img: require("../../assets/icon/icon_single.png"),
          type: 1,
        },
        {
          title: "多选题",
          img: require("../../assets/icon/icon_multi.png"),
          type: 2,
        },
      ],
    };
  },
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    Back() {
      this.$emit("specialBack");
    },
    specialChoose(item) {
      this.$emit("specialChoose",item.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: white;
  overflow-x: hidden;
}
.tabbar-container {
  width: 100%;
  text-align: center;
  color: black;
  margin: 1rem 0;
  position: relative;
  font-size: 1.1rem;

  .popup-back {
    position: absolute;
    left: 20px;
    top: 5px;
  }
}

.middle-container {
  width: 100%;
  flex: 1;
  background: #f7f7f7;
  padding: 1rem;
  box-sizing: border-box;

  .middle-view {
    background: white;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;
  }
}
</style>
