<template>
  <div class="wrongQue-container">
    <div class="tabbar-container">
      <van-icon class="popup-back"
                @click="Back"
                name="arrow-left" />
      错题集
    </div>
    <div class="middle-container">
      <div @click="showTime"
           class="search-container">
        <div>{{ searchTime }}</div>
        <div class="search-icon">
          <van-icon @click.stop="close"
                    style="margin-right: 1rem"
                    name="close" />
          <van-icon @click.stop="search"
                    name="search" />
        </div>
      </div>
      <div class="message-container">
        <div v-for="(item, index) in listData"
             class="message-item"
             :key="index">
          <div>{{ `${long2string(item.submitdate)}` }}</div>
          <div @click="showWrong(item)"
               class="message-button">错题详情</div>
        </div>
      </div>
    </div>

    <van-popup v-model="showTimePick"
               position="bottom">
      <van-datetime-picker v-model="currentDate"
                           type="date"
                           @cancel="cancel"
                           @confirm="timeConfirm"
                           title="选择时间" />
    </van-popup>

    <van-popup v-model="showWrongMessage"
               position="right"
               :lock-scroll="false"
               :style="{ width: '100%', height: '100%' }">
      <wrong-message v-if="showWrongMessage"
                     :message="message"
                     @wrongMessageBack="wrongMessageBack" />
    </van-popup>
  </div>
</template>

<script>
import wrongMessage from './worngMessage.vue';
export default {
  name: 'wrongQue',
  components: { wrongMessage },
  computed: {},
  data() {
    return {
      // 时间默认当前时间。
      currentDate: new Date(),
      showWrongMessage: false,
      searchTime: '',
      startdate: 0,
      enddate: 0,
      showTimePick: false,

      listData: [],
      message: {}
    };
  },
  watch: {},
  created() {},
  async mounted() {
    await this.loadData();
  },
  destroyed() {},
  methods: {
    async loadData() {
      var cuotiListForm;
      if (this.startdate === 0 && this.enddate === 0) {
        cuotiListForm = {
          khid: localStorage.getItem('openid')
        };
      } else {
        cuotiListForm = {
          khid: localStorage.getItem('openid'),
          startdate: this.startdate,
          enddate: this.enddate
        };
      }
      const CuoTiList = await this.$store.dispatch(
        'api/getCuoTiList',
        cuotiListForm
      );
      if (CuoTiList.success) {
        this.listData = CuoTiList.result;
      } else {
        this.$toast(CuoTiList.message);
      }
    },
    Back() {
      this.$emit('wrongQueBack');
    },
    long2string(long) {
      return this.$timestampTostr(new Date(long));
    },
    wrongMessageBack() {
      this.showWrongMessage = false;
    },
    timeConfirm(row) {
      this.searchTime = this.$timestampTostr(
        new Date(row).getTime(),
        'yyyy-mm-dd'
      ).replace(' ', '');
      this.startdate = new Date(row).getTime();
      this.enddate = new Date(row).getTime() + 1000 * 60 * 60 * 24;
      this.cancel();
    },
    cancel() {
      this.showTimePick = false;
    },
    showTime() {
      this.showTimePick = true;
    },
    async search() {
      await this.loadData();
    },
    close() {
      this.searchTime = '';
      this.startdate = '';
      this.enddate = '';
    },
    showWrong(item) {
      this.message = item;
      this.showWrongMessage = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrongQue-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#2d8efc, #1ccaa3);
  overflow-x: hidden;
}
.tabbar-container {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 1rem;
  position: relative;
  font-size: 1.1rem;

  .popup-back {
    position: absolute;
    left: 20px;
    top: 5px;
  }
}
.middle-container {
  padding: 1rem;
  width: 100%;
  height: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;

  .search-container {
    width: 100;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1.5rem;
    justify-content: space-between;
    font-size: 0.9rem;
    box-sizing: border-box;
    color: white;
    border-radius: 999px;
    background: rgba($color: white, $alpha: 0.2);

    .search-icon {
      font-size: 1.2rem;
    }
  }

  .message-container {
    width: 100;
    height: 0;
    overflow-y: auto;
    flex: 1;

    .message-item {
      width: 100%;
      height: 3.5rem;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      flex-direction: row;
      font-weight: bold;
      padding: 0 1rem;
      font-size: 1rem;
      box-sizing: border-box;
      justify-content: space-between;
      background: white;
      border-radius: 10px;

      .message-button {
        width: 5.5rem;
        height: 2rem;
        border-radius: 999px;
        display: flex;
        font-size: 0.9rem;
        align-items: center;
        justify-content: center;
        color: white;
        background: #00afe8;
      }
    }
  }
}
</style>
