<template>
  <div class="que-container">
    <div class="tabbar-container">
      <van-icon class="popup-back"
                @click="FatherBack"
                name="arrow-left" />
      开始答题
    </div>

    <div class="middle-container">
      <div class="que-tips">
        <div class="que-count">第{{ queCount + 1 }}题</div>
        <div class="que-count"
             style="color: #1dc5aa">得分：{{ fraction }}</div>
        <div class="que-type">{{ que[queCount].type }}</div>
      </div>
      <div class="que-message">
        <div class="que-title">{{ que[queCount].title }}</div>

        <div v-for="(item, index) in que[queCount].options"
             @click="chooseXx(index)"
             v-show="!que[queCount].states"
             :key="index"
             class="que-xx"
             :class="item.choose ? 'que-xx-choose' : ''">
          {{ item.ans
          }}{{
            que[queCount].type === "判断" ? "" : ".&nbsp;&nbsp;" + item.text
          }}
          <van-icon :name="item.choose ? 'circle' : ''" />
        </div>

        <div v-for="(item, index) in que[queCount].options"
             v-show="que[queCount].states"
             :key="index + 10"
             class="que-xx"
             :class="
            item.choose && que[queCount].answer.indexOf(item.ans) != -1
              ? 'que-xx-choose-success'
              : item.choose
              ? 'que-xx-choose-dfed'
              : ''
          ">
          {{ item.ans
          }}{{
            que[queCount].type === "判断" ? "" : ".&nbsp;&nbsp;" + item.text
          }}
          <van-icon :color="
              que[queCount].answer.indexOf(item.ans) != -1
                ? '#11D150'
                : item.choose
                ? '#FF0000'
                : ''
            "
                    :name="
              que[queCount].answer.indexOf(item.ans) != -1
                ? 'success'
                : item.choose
                ? 'cross'
                : ''
            " />
        </div>

        <div v-show="que[queCount].states"
             :style="
            que[this.queCount].answer === que[this.queCount].userAnswer
              ? 'color: #11D150'
              : 'color: #FF0000'
          "
             style="text-align: center; font-weight: 500; margin-top: 1rem">
          {{
            que[this.queCount].answer === que[this.queCount].userAnswer
              ? "回答正确"
              : "回答错误"
          }}
        </div>
        <div v-show="que[queCount].states"
             style="text-align: center; font-weight: 500; margin-top: 0.5rem">
          正确答案:
          <span style="color: #11d150">{{ que[this.queCount].answer }}</span>
        </div>
      </div>

      <div class="que-go">
        <van-button class="que-gobutton"
                    round
                    :disabled="backDis"
                    @click="back"
                    color="linear-gradient(to right, #1CCAA3, #2D8FFC)">
          上一题
        </van-button>
        <van-button class="que-gobutton"
                    round
                    :disabled="nextDis"
                    @click="next"
                    color="linear-gradient(to right, #1CCAA3, #2D8FFC)">
          {{ nextText }}
        </van-button>
      </div>
    </div>

    <div class="bottom-container">
      <div @click="finish"
           class="bottom-jj">交卷</div>
      <div style="flex: 1; display: flex; flex-direction: row">
        <van-icon name="success"
                  color="#1DD358" />
        <div style="color: #1dd358; margin-right: 1rem; margin-left: 0.5rem">
          {{ correct }}
        </div>
        <van-icon name="cross"
                  color="#FF0000" />
        <div style="color: #ff0000; margin-right: 1rem; margin-left: 0.5rem">
          {{ wrong }}
        </div>
      </div>
      <div style="font-size: 0.9rem">
        {{ queCount + 1 }}&nbsp;/&nbsp;{{ que.length }}
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  name: 'que',
  components: {},
  computed: {},
  props: {
    queForm: {
      type: Object,
      require: true,
      default: () => ({})
    },
    train: {
      type: Object,
      require: true,
      default: () => ({})
    },
    examinType: {
      type: Number,
      require: true,
      default: 0
    }
  },
  data() {
    return {
      // 下一题按钮文字。
      nextText: '确定',

      // 下一题禁用。
      nextDis: false,
      // 上一题禁用。
      backDis: true,

      // 单选分数。
      singleScore: 1,
      // 多选分数。
      multiScore: 1,
      // 判断分数。
      judgeScore: 1,
      // 及格分数。
      passScore: 60,

      // 正确题目数量。
      correct: 0,
      // 错误题目数量。
      wrong: 0,
      // 当前题目数。
      queCount: 0,
      // 当前分数。
      fraction: 0,

      // 题目主题集合。
      que: [{ type: '...', title: '...' }],
      // 错题集合
      ctList: []
    };
  },
  watch: {},
  created() {},
  async mounted() {
    this.singleScore = this.queForm.singleScore;
    this.multiScore = this.queForm.multiScore;
    this.judgeScore = this.queForm.judgeScore;
    this.passScore = this.queForm.passScore;

    await this.$store
      .dispatch('api/getSimulatePaper', this.queForm)
      .then((response) => {
        this.que = response.result;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  destroyed() {},
  methods: {
    FatherBack() {
      Dialog.confirm({
        title: '是否退出答题',
        confirmButtonText: '是',
        cancelButtonText: '否'
      })
        .then(() => {
          this.$emit('fatherMethod');
        })
        .catch(() => {});
    },
    finish() {
      if (this.examinType === 1) {
        Dialog.confirm({
          title: '是否交卷',
          message: `得分：${this.fraction}\n回答正确：${this.correct}题 - 回答错误：${this.wrong}题`,
          confirmButtonText: '是',
          cancelButtonText: '否'
        })
          .then(() => {
            this.save();
          })
          .catch(() => {});
      } else {
        Dialog.confirm({
          title: '是否交卷',
          message: `得分：${this.fraction}\n考试成绩：${
            Number(this.passScore) <= Number(this.fraction) ? '及格' : '不及格'
          }\n回答正确：${this.correct}题 - 回答错误：${this.wrong}题`,
          confirmButtonText: '是',
          cancelButtonText: '否'
        })
          .then(() => {
            this.save();
          })
          .catch(() => {});
      }
    },
    async save() {
      // examinType为0是计入练习分析。自定义练习和专项只提交错题。
      if (this.examinType === 0) {
        const studentHistory = await this.$store.dispatch('api/getStudent', {
          id: localStorage.getItem('openid')
        });
        if (!studentHistory.success) {
          this.$toast('提交答题失败，请重试');
          return;
        }

        const studentForm = {
          studentid: localStorage.getItem('openid'), //openid
          testnum: Number(studentHistory.result.testnum) + 1, //练习次数
          testpassnum:
            Number(this.passScore) <= Number(this.fraction)
              ? Number(studentHistory.result.testpassnum) + 1
              : Number(studentHistory.result.testpassnum), //练习及格次数
          testquesnum:
            Number(studentHistory.result.testquesnum) +
            this.correct +
            this.wrong, //练习答题数量
          testrightnum:
            Number(studentHistory.result.testrightnum) + this.correct //对题数量
        };

        const student = await this.$store.dispatch(
          'api/saveStudent',
          studentForm
        );

        var submitForm = {
          khid: localStorage.getItem('openid'),
          score: this.fraction,
          teststatus:
            Number(this.passScore) <= Number(this.fraction) ? '及格' : '不及格'
        };
        if (this.train && this.train.classid) {
          submitForm.classid = this.train.classid;
          submitForm.hylb = this.train.hylb;
          submitForm.hylbid = this.train.hylbid;
        }
        const submit = await this.$store.dispatch(
          'api/SubmitSocre',
          submitForm
        );

        if (this.ctList.length > 0) {
          const cuoti = await this.$store.dispatch(
            'api/SaveCuoTi',
            this.ctList
          );

          if (submit.success && cuoti.success && student.success) {
            this.$emit('fatherMethod');
          } else {
            this.$toast('交卷失败请重试');
          }
        } else {
          if (submit.success && student.success) {
            this.$emit('fatherMethod');
          } else {
            this.$toast('交卷失败请重试');
          }
        }
      } else {
        if (this.ctList.length > 0) {
          const cuoti = await this.$store.dispatch(
            'api/SaveCuoTi',
            this.ctList
          );

          if (cuoti.success) {
            this.$emit('fatherMethod');
          } else {
            this.$toast('交卷失败请重试');
          }
        } else {
          this.$emit('fatherMethod');
        }
      }
    },
    back() {
      this.queCount = this.queCount - 1;
      this.nextDis = false;

      if (this.que[this.queCount].states) {
        this.nextText = '下一题';
      }

      if (this.queCount === 0) {
        this.backDis = true;
        return;
      }
    },
    next() {
      var t = true;
      this.que[this.queCount].options.forEach((q) => {
        if (q.choose) {
          t = false;
        }
      });
      if (t) {
        this.$toast('请选择答案');
        return;
      }

      if (!this.que[this.queCount].states && this.nextText === '确定') {
        // 确定答案 切换样式。
        this.que[this.queCount].options.forEach((q) => {
          if (q.choose) {
            if (!this.que[this.queCount].userAnswer) {
              this.que[this.queCount].userAnswer = '';
            }
            this.que[this.queCount].userAnswer =
              this.que[this.queCount].userAnswer + q.ans;
          }
        });

        if (
          this.que[this.queCount].answer === this.que[this.queCount].userAnswer
        ) {
          this.correct = this.correct + 1;
          switch (this.que[this.queCount].type) {
            case '单选':
              this.fraction = this.fraction + this.singleScore;
              break;
            case '多选':
              this.fraction = this.fraction + this.multiScore;
              break;
            case '判断':
              this.fraction = this.fraction + this.judgeScore;
              break;
          }
        } else {
          // 保存错题
          this.ctList.push({
            khid: localStorage.getItem('openid'),
            shiti: this.que[this.queCount].title,
            xuanxiang: JSON.stringify(this.que[this.queCount].options),
            useranswers: this.que[this.queCount].userAnswer,
            paperanswers: this.que[this.queCount].answer,
            ttype: this.que[this.queCount].type
          });
          this.wrong = this.wrong + 1;
        }

        this.que[this.queCount].states = true;
        this.nextText = '下一题';
      } else if (this.nextText === '下一题') {
        // 题目加一， 解放上一题按钮。
        this.queCount = this.queCount + 1;
        this.backDis = false;

        // 没答题记录，变为确定按钮。
        if (!this.que[this.queCount].states) {
          this.nextText = '确定';
          return;
        }
      }

      if (this.queCount + 1 === this.que.length) {
        this.nextDis = true;
        return;
      }
    },
    chooseXx(index) {
      if (this.que[this.queCount].states) {
        return;
      }

      if (this.que[this.queCount].type === '多选') {
        this.que[this.queCount].options[index].choose =
          !this.que[this.queCount].options[index].choose;
      } else {
        // 单选重复选择后不进行操作
        if (this.que[this.queCount].options[index].choose) return;

        // 单选切换选择
        this.que[this.queCount].options.forEach((que, queIndex) => {
          if (index === queIndex) {
            que.choose = true;
          } else {
            que.choose = false;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.que-container {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#2d8efc, #1ccaa3);
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.tabbar-container {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 1rem;
  position: relative;
  font-size: 1.1rem;

  .popup-back {
    position: absolute;
    left: 20px;
    top: 5px;
  }
}

.middle-container {
  width: 90%;
  height: 0;
  flex: 1;
  margin: 1.8rem 0;
  background: white;
  border-radius: 10px;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  padding: 1rem;
  box-sizing: border-box;

  .que-tips {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .que-count {
      color: #666666;
      font-size: 0.9rem;
    }
    .que-type {
      color: white;
      font-size: 0.8rem;
      background: #00afe8;
      border-radius: 5px;
      padding: 0.15rem 0.35rem;
    }
  }

  .que-message {
    width: 100%;
    flex: 1;
    height: 0;
    margin: 0.5rem 0;
    overflow-y: auto;

    .que-title {
      text-align: left;
      color: #333333;
      font-size: 1rem;
      padding-bottom: 0.5rem;
    }

    .que-xx {
      width: calc(100% - 2px);
      min-height: 2rem;
      background: #fbfbfb;
      border: #e5e5e5 solid 1px;
      border-radius: 5px;
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      color: #333333;
      font-weight: bold;
      box-sizing: border-box;
    }

    .que-xx-choose {
      color: #00afe8;
      border: #00afe8 solid 1px;
    }

    .que-xx-choose-success {
      color: #11d150;
      border: #00afe8 solid 1px;
    }

    .que-xx-choose-dfed {
      color: #ff0000;
      border: #00afe8 solid 1px;
    }
  }

  .que-go {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .que-gobutton {
      width: 45%;
      height: 100%;
    }
  }
}

.bottom-container {
  width: 100%;
  height: 40px;
  background: white;
  display: flex;
  padding: 0 1rem;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;

  .bottom-jj {
    color: white;
    margin-right: 1rem;
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    border-radius: 999px;
    background-image: linear-gradient(45deg, #2d8efc, #1ccaa3);
  }
}
</style>
