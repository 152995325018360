<template>
  <div class="customList-container">
    <div class="tabbar-container">
      <van-icon class="popup-back"
                @click="Back"
                name="arrow-left" />
      <van-icon class="popup-add"
                @click="Add"
                name="add-o" />
      自定义考场
    </div>
    <div class="middle-container">
      <div v-for="(item, index) in dataList"
           @click="Self(item)"
           class="message-list"
           :key="index">
        <div class="message-item">
          <div style="color:#333333">{{ item.planname }}</div>
          <div class="message-button">
            {{ customType === 0 ? "自定义详情" : "选择" }}
          </div>
        </div>

        <div class="message-item"
             style="font-weight: normal; margin-top: 0.5rem; font-size:0.95rem">
          <div>判断:{{item.pannum}} 题</div>
          <div>单选:{{item.dannum}} 题</div>
          <div>多选:{{item.duonum}} 题</div>
        </div>
        <div style="font-weight: normal; margin-top: 0.5rem; font-size:0.95rem">及格分数：<span style="color: #00afe8">{{item.cutoffscores}}分</span></div>
      </div>

    </div>

    <van-popup v-model="showCustom"
               position="right"
               :lock-scroll="false"
               :style="{ width: '100%', height: '100%' }">
      <custom v-if="showCustom"
              :self="self"
              @customBack="customBack" />
    </van-popup>
  </div>
</template>

<script>
import custom from './custom.vue';
export default {
  name: 'customList',
  components: { custom },
  computed: {},
  props: {
    customType: {
      type: Number,
      require: true,
      default: 0
    }
  },
  data() {
    return {
      showCustom: false,
      dataList: [],
      self: {}
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.loadData();
  },
  destroyed() {},
  methods: {
    loadData() {
      const from = {
        size: 999,
        currentPage: 1,
        query: {
          openid: localStorage.getItem('openid')
        }
      };
      this.$store
        .dispatch('api/getSelfDataList', from)
        .then((response) => {
          if (response.success) {
            this.dataList = response.result.rows;
          } else {
            this.$toast(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    Back() {
      this.$emit('customListBack');
    },
    Add() {
      this.self = {
        cutoffscores: 60,
        dannum: 30,
        danscore: 1,
        duonum: 20,
        duoscore: 1,
        openid: localStorage.getItem('openid'),
        pannum: 50,
        panscore: 1,
        planname: '',
        testplanid: ''
      };
      this.showCustom = true;
    },
    customBack() {
      this.showCustom = false;
      this.loadData();
    },
    Self(item) {
      // 从首页跳转为编辑。
      if (this.customType === 0) {
        this.self = {
          cutoffscores: item.cutoffscores,
          dannum: item.dannum,
          danscore: item.danscore,
          duonum: item.duonum,
          duoscore: item.duoscore,
          openid: item.openid,
          pannum: item.pannum,
          panscore: item.panscore,
          planname: item.planname,
          testplanid: item.testplanid
        };
        this.showCustom = true;
      } else if (this.customType === 1) {
        // 答题页面跳转为选中。
        this.$emit('customListBack');
        this.$emit('customChoose', item);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.customList-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#2d8efc, #1ccaa3);
  overflow-x: hidden;
}
.tabbar-container {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  font-size: 1.1rem;

  .popup-back {
    position: absolute;
    left: 20px;
    top: 5px;
  }
  .popup-add {
    position: absolute;
    right: 20px;
    top: 5px;
  }
}

.middle-container {
  width: 100%;
  height: 0;
  padding: 0 1rem;
  box-sizing: border-box;
  overflow-y: auto;
  flex: 1;

  .message-list {
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    background: white;
    color: #a9a9a9;
    margin-top: 1rem;
    border-radius: 10px;

    .message-item {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      font-weight: bold;
      font-size: 1rem;
      justify-content: space-between;

      .message-button {
        width: 5.5rem;
        height: 2rem;
        border-radius: 999px;
        display: flex;
        font-size: 0.9rem;
        align-items: center;
        justify-content: center;
        color: white;
        background: #00afe8;
      }
    }
  }
}

/deep/ .van-cell {
  text-align: left;
}

.checkout {
  width: 90%;
  margin-left: 5%;
  height: 44px;
  border-radius: 999px;
  display: flex;
  align-items: row;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(270deg, #2d8efc, #1ccaa3);
  color: #ffffff;
  font-size: 1rem;
}
</style>
