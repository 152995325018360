<template>
  <div class="examination-container">
    <div class="tabbar-container">
      <van-icon class="popup-back"
                @click="FatherBack"
                name="arrow-left" />
      {{
        examinType === 0
          ? "模拟考试"
          : examinType === 2
          ? "自定义考场"
          : specialType === 0
          ? "判断题专项练习"
          : specialType === 1
          ? "单选题专项练习"
          : "多选题专项练习"
      }}
    </div>

    <van-image class="avatar"
               width="4rem"
               height="4rem"
               round
               fit="contain"
               :src="avatar" />
    <div class="middle-container">
      <div style="padding-top: 2.8rem">{{ nickname }}</div>
      <div style="font-size: 4rem; color: #01c65a">{{ jgl }}%</div>
      <div style="">天道酬勤，百炼成钢</div>
      <div class="line" />

      <div class="middle-middle">
        <div>
          <div class="middle-middle-toptext">{{ testnum }}</div>
          <div class="middle-middle-bottomtext">模考次数</div>
        </div>
        <div>
          <div class="middle-middle-toptext">{{ testpassnum }}</div>
          <div class="middle-middle-bottomtext">合格次数</div>
        </div>
        <div>
          <div class="middle-middle-toptext">{{ testbadnum }}</div>
          <div class="middle-middle-bottomtext">不合格次数</div>
        </div>
      </div>

      <div class="middle-bottom">
        <van-cell :title="exType"
                  style="font-size: 0.9rem"
                  @click="exTypeShow"
                  is-link />
        <van-cell v-show="trainData && trainData.length > 0"
                  style="margin-top: 0.5rem; font-size: 0.9rem"
                  :title="`练习班级：${classname}`"
                  @click="showTrain = true"
                  is-link />
        <div @click="startQue"
             class="start">开始练习</div>
        <div v-show="examinType != 1"
             @click="useCustom"
             class="self">
          使用自定义考场
        </div>
      </div>
    </div>

    <van-popup v-model="showType"
               round
               position="bottom">
      <van-picker title="考试类型"
                  show-toolbar
                  :columns="typeData"
                  @confirm="confirmType"
                  @cancel="onCancel" />
    </van-popup>
    <van-popup v-model="showTrain"
               round
               position="bottom">
      <van-picker title="练习班级"
                  show-toolbar
                  :columns="trainData"
                  @confirm="confirmTarin"
                  @cancel="onCancel" />
    </van-popup>

    <van-popup v-model="showQue"
               position="right"
               :lock-scroll="false"
               :style="{ width: '100%', height: '100%' }">
      <que v-if="showQue"
           :train="train"
           :examinType="examinType"
           :queForm="queForm"
           @fatherMethod="Back" />
    </van-popup>

    <van-popup v-model="showCustomList"
               position="right"
               :lock-scroll="false"
               :style="{ width: '100%', height: '100%' }">
      <custom-list v-if="showCustomList"
                   :customType="customType"
                   @customChoose="customChoose"
                   @customListBack="customListBack" />
    </van-popup>

    <transition name="van-fade">
      <div class="fade"
           v-show="visiable">{{ tipMessage }}</div>
    </transition>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import que from './que.vue';
import customList from './customList.vue';
export default {
  name: 'examination',
  components: { que, customList },
  computed: {},
  props: {
    queType: {
      type: Number,
      require: true,
      default: 0
    },
    specialType: {
      type: Number,
      require: false,
      default: 0
    }
  },
  data() {
    return {
      examinType: 0,
      customType: 1,

      jgl: 0,
      testnum: 0,
      testpassnum: 0,
      testbadnum: 0,

      // 当前选中类型文字展示。
      exType: '',
      // 当前选中类型数据。
      exTypeData: {},
      // 考试类型文字列表
      typeData: [],
      // 开始考试参数
      queForm: {
        quesID: '',
        passScore: '80',
        singleNum: 30,
        singleScore: 1,
        multiNum: 20,
        multiScore: 1,
        judgeNum: 50,
        judgeScore: 1,
        testTime: '60'
      },
      queFormSelf: {},
      classname: '',
      trainData: [],
      train: {},
      showTrain: false,

      // 展示考试类型。
      showType: false,
      // 展示题目。
      showQue: false,
      // 展示自定义考场。
      showCustomList: false,

      mnks: require('../../assets/icon/icon_mnks.png'),
      avatar: localStorage.getItem('headimgurl'),
      nickname: localStorage.getItem('nickname'),

      // 提示。
      visiable: false,
      tipMessage: ''
    };
  },
  watch: {},
  created() {},
  async mounted() {
    this.examinType = this.queType;

    await this.loadStudent();

    const response = await this.$store.dispatch('api/getAllQuesconect');

    this.typeData = response.result;
    this.typeData.forEach((t, index) => {
      this.typeData[index].text = t.dname;
    });

    this.confirmType(this.typeData[0]);
  },
  destroyed() {},
  methods: {
    async loadStudent() {
      const student = await this.$store.dispatch('api/getStudent', {
        id: localStorage.getItem('openid')
      });

      this.testnum = student.result.testnum;
      this.testpassnum = student.result.testpassnum;
      this.testbadnum = Number(this.testnum) - Number(this.testpassnum);

      if (this.testnum != 0) {
        this.jgl = Math.floor(
          ((Number(this.testpassnum) / Number(this.testnum)) * 10000) / 100
        );
      }
    },
    async startQue() {
      if (!this.exTypeData.quesid) {
        this.tipMessage('请选择考试类别');
      }

      if (this.exTypeData.needpay === 1) {
        const studentMessage = await this.$store.dispatch(
          'api/getUserInfoForId',
          {
            studentid: localStorage.getItem('openid')
          }
        );
        // 验证身份号是否存在。
        if (!studentMessage.result.idcard) {
          Dialog.confirm({
            message: '身份证号不能为空\n请去【我的-信息完善】中补全身份证',
            showCancelButton: true,
            confirmButtonText: '去完善'
          }).then(() => {
            this.$emit('fatherMethod2');
          });
        } else {
          // 身份证号存在 验证是否付费。
          var checkPayForm = {
            idCard: studentMessage.result.idcard,
            quesid: this.exTypeData.quesid
          };
          if (this.train && this.train.classid) {
            checkPayForm.classid = this.train.classid;
          }
          const checkPay = await this.$store.dispatch(
            'api/checkPay',
            checkPayForm
          );

          if (checkPay.success) {
            // 已经付费开始答题。
            this.beforeQue();
          } else {
            // 未付费或无次数提示。
            this.selfQue(checkPay.message);
          }
        }
      } else {
        // 无需付费直接答题。
        this.beforeQue();
      }
    },
    beforeQue() {
      // TODO 判断是模拟还是专项。

      if (this.examinType === 0) {
        this.queForm = {
          quesID: this.exTypeData.quesid,
          passScore: '80',
          singleNum: 30,
          singleScore: 1,
          multiNum: 20,
          multiScore: 1,
          judgeNum: 50,
          judgeScore: 1,
          testTime: '60'
        };
      } else if (this.examinType === 1) {
        // 使用专项练习数据
        this.queForm = {
          quesID: this.exTypeData.quesid,
          passScore: '60',
          singleNum: this.specialType === 1 ? 10000 : 0,
          singleScore: 1,
          multiNum: this.specialType === 2 ? 10000 : 0,
          multiScore: 1,
          judgeNum: this.specialType === 0 ? 10000 : 0,
          judgeScore: 1,
          testTime: '60'
        };
      } else if (this.examinType === 2) {
        // TODO查自定义考场接口
        this.queForm = {
          quesID: this.exTypeData.quesid,
          passScore: this.queFormSelf.cutoffscores,
          singleNum: this.queFormSelf.dannum,
          singleScore: this.queFormSelf.danscore,
          multiNum: this.queFormSelf.duonum,
          multiScore: this.queFormSelf.duoscore,
          judgeNum: this.queFormSelf.pannum,
          judgeScore: this.queFormSelf.panscore,
          testTime: '60'
        };
      }
      this.showQue = true;
    },
    selfQue(tipMessage) {
      this.tipMessage = tipMessage;
      if (this.visiable) return;

      this.visiable = true;
      setTimeout(() => {
        this.visiable = false;
      }, 1500);
    },
    Back() {
      this.showQue = false;
      this.loadStudent();
    },
    FatherBack() {
      this.$emit('fatherMethod');
    },
    customListBack() {
      this.showCustomList = false;
    },
    customChoose(item) {
      this.examinType = 2;
      this.queFormSelf = item;
    },
    useCustom() {
      this.showCustomList = true;
    },
    exTypeShow() {
      this.showType = true;
    },
    async confirmType(row) {
      this.exType = row.text;
      this.exTypeData = row;

      if (this.exTypeData.needpay) {
        const trainList = await this.$store.dispatch('api/getTrainList', {
          hylbid: this.exTypeData.did
        });
        this.trainData = trainList.result;
        this.trainData.forEach((t, index) => {
          this.trainData[index].text = t.classname;
        });

        this.confirmTarin(this.trainData[0]);
      } else {
        this.trainData = [];
        this.classname = '';
        this.train = {};
      }

      this.showType = false;
    },
    confirmTarin(row) {
      this.classname = row.classname;
      this.train = row;
      this.showTrain = false;
    },
    onCancel() {
      this.showType = false;
      this.showTrain = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.examination-container {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#2d8efc, #1ccaa3);
  overflow-x: hidden;
  text-align: center;
}

.tabbar-container {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 1rem;
  position: relative;
  font-size: 1.1rem;

  .popup-back {
    position: absolute;
    left: 20px;
    top: 5px;
  }
}

.middle-container {
  width: 90%;
  height: calc(100% - 9rem);
  margin-top: 3rem;
  background: white;
  border-radius: 10px;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  font-weight: normal;

  .middle-middle {
    display: flex;
    flex-direction: row;
    padding: 0 1.5rem;
    margin-top: 1rem;
    text-align: center;
    justify-content: space-between;

    .middle-middle-toptext {
      color: #333333;
      font-weight: bold;
      font-size: 2rem;
    }

    .middle-middle-bottomtext {
      color: #9a9a9a;
      font-size: 0.9rem;
    }
  }
  .middle-bottom {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .start {
      width: 80%;
      height: 40px;
      border-radius: 999px;
      display: flex;
      align-items: row;
      align-items: center;
      margin-top: 0.5rem;
      justify-content: center;
      background-image: linear-gradient(270deg, #2d8efc, #1ccaa3);
      color: #ffffff;
      font-size: 1rem;
    }

    /deep/ .van-cell {
      width: 80%;
      min-height: 40px;
      border: #cccccc solid 1px;
      border-radius: 999px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      padding: 5px 20px;
      color: #333333;
      font-size: 1rem;
    }
    .self {
      width: 80%;
      height: 40px;
      border: #333333 solid 1px;
      border-radius: 999px;
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      justify-content: center;
      color: #333333;
      font-size: 1rem;
    }
  }
}

.avatar {
  position: absolute;
  top: 4rem;
  left: calc(50% - 2rem);
}

.line {
  width: 86%;
  margin-left: 7%;
  background: #eeeeee;
  height: 1px;
  margin-top: 1rem;
}

.fade {
  width: 60%;
  height: 6%;
  border-radius: 5px;
  top: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 20%;
  position: absolute;
  background-image: linear-gradient(270deg, #2d8efc, #1ccaa3);
  color: #ffffff;
}
</style>
