var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"que-container"},[_c('div',{staticClass:"tabbar-container"},[_c('van-icon',{staticClass:"popup-back",attrs:{"name":"arrow-left"},on:{"click":_vm.FatherBack}}),_vm._v(" 开始答题 ")],1),_c('div',{staticClass:"middle-container"},[_c('div',{staticClass:"que-tips"},[_c('div',{staticClass:"que-count"},[_vm._v("第"+_vm._s(_vm.queCount + 1)+"题")]),_c('div',{staticClass:"que-count",staticStyle:{"color":"#1dc5aa"}},[_vm._v("得分："+_vm._s(_vm.fraction))]),_c('div',{staticClass:"que-type"},[_vm._v(_vm._s(_vm.que[_vm.queCount].type))])]),_c('div',{staticClass:"que-message"},[_c('div',{staticClass:"que-title"},[_vm._v(_vm._s(_vm.que[_vm.queCount].title))]),_vm._l((_vm.que[_vm.queCount].options),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.que[_vm.queCount].states),expression:"!que[queCount].states"}],key:index,staticClass:"que-xx",class:item.choose ? 'que-xx-choose' : '',on:{"click":function($event){return _vm.chooseXx(index)}}},[_vm._v(" "+_vm._s(item.ans)+_vm._s(_vm.que[_vm.queCount].type === "判断" ? "" : ".  " + item.text)+" "),_c('van-icon',{attrs:{"name":item.choose ? 'circle' : ''}})],1)}),_vm._l((_vm.que[_vm.queCount].options),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.que[_vm.queCount].states),expression:"que[queCount].states"}],key:index + 10,staticClass:"que-xx",class:item.choose && _vm.que[_vm.queCount].answer.indexOf(item.ans) != -1
            ? 'que-xx-choose-success'
            : item.choose
            ? 'que-xx-choose-dfed'
            : ''},[_vm._v(" "+_vm._s(item.ans)+_vm._s(_vm.que[_vm.queCount].type === "判断" ? "" : ".  " + item.text)+" "),_c('van-icon',{attrs:{"color":_vm.que[_vm.queCount].answer.indexOf(item.ans) != -1
              ? '#11D150'
              : item.choose
              ? '#FF0000'
              : '',"name":_vm.que[_vm.queCount].answer.indexOf(item.ans) != -1
              ? 'success'
              : item.choose
              ? 'cross'
              : ''}})],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.que[_vm.queCount].states),expression:"que[queCount].states"}],staticStyle:{"text-align":"center","font-weight":"500","margin-top":"1rem"},style:(_vm.que[this.queCount].answer === _vm.que[this.queCount].userAnswer
            ? 'color: #11D150'
            : 'color: #FF0000')},[_vm._v(" "+_vm._s(_vm.que[this.queCount].answer === _vm.que[this.queCount].userAnswer ? "回答正确" : "回答错误")+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.que[_vm.queCount].states),expression:"que[queCount].states"}],staticStyle:{"text-align":"center","font-weight":"500","margin-top":"0.5rem"}},[_vm._v(" 正确答案: "),_c('span',{staticStyle:{"color":"#11d150"}},[_vm._v(_vm._s(_vm.que[this.queCount].answer))])])],2),_c('div',{staticClass:"que-go"},[_c('van-button',{staticClass:"que-gobutton",attrs:{"round":"","disabled":_vm.backDis,"color":"linear-gradient(to right, #1CCAA3, #2D8FFC)"},on:{"click":_vm.back}},[_vm._v(" 上一题 ")]),_c('van-button',{staticClass:"que-gobutton",attrs:{"round":"","disabled":_vm.nextDis,"color":"linear-gradient(to right, #1CCAA3, #2D8FFC)"},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.nextText)+" ")])],1)]),_c('div',{staticClass:"bottom-container"},[_c('div',{staticClass:"bottom-jj",on:{"click":_vm.finish}},[_vm._v("交卷")]),_c('div',{staticStyle:{"flex":"1","display":"flex","flex-direction":"row"}},[_c('van-icon',{attrs:{"name":"success","color":"#1DD358"}}),_c('div',{staticStyle:{"color":"#1dd358","margin-right":"1rem","margin-left":"0.5rem"}},[_vm._v(" "+_vm._s(_vm.correct)+" ")]),_c('van-icon',{attrs:{"name":"cross","color":"#FF0000"}}),_c('div',{staticStyle:{"color":"#ff0000","margin-right":"1rem","margin-left":"0.5rem"}},[_vm._v(" "+_vm._s(_vm.wrong)+" ")])],1),_c('div',{staticStyle:{"font-size":"0.9rem"}},[_vm._v(" "+_vm._s(_vm.queCount + 1)+" / "+_vm._s(_vm.que.length)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }